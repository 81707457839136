<template>
  <Layout tituloPagina="Productos | Categorías">
    <div class="row">
      <div class="col-lg-5">
        <cmp-secciones seccion="categorias"></cmp-secciones>
      </div>
    </div>
    <div class="row">
      <div class="offset-md-2 col-md-8 col-9 mb-3">
        <filtrador
          v-on:dato-actualizado="
            dato = $event,
            refrescarCategorias()
          "
          :datoini="dato"
          placeholder="Dato de la categoría"
        ></filtrador>
      </div>
      <div class="col-md-2 col-3 text-right">
        <router-link
          :to="{ name: 'nuevaCategoriaProductos' }"
          class="btn btn-success"
        >
          <span class="d-none d-sm-block">
            <i class="mdi mdi-plus-thick"></i>
            Nueva
          </span>
          <span class="d-block d-sm-none">
            <i class="mdi mdi-plus-thick"></i>
          </span>
        </router-link>
      </div>
    </div>

    <div class="row">
      <div class="col-md-4 offset-md-8 text-right" style="padding-top: 10px;">
        <span class="badge bg-secondary bg-gradient font-size-12">
          {{nCategoriasEnPag}} categoría en página
        </span>
        <span class="badge bg-secondary bg-gradient font-size-12">
          {{nCategoriasTotales}} categoría en total
        </span>
      </div>
    </div>

    <br />

    <div>
      <div class="card">
        <div class="card-body" style="min-height: 475px;">
          <div class="table-responsive">
            <table class="table table-hover" style="min-width: 850px" id="tabla-categorias">
              <thead>
                <tr>
                  <th style="width: 50px">Img</th>
                  <th style="width: 70px">ID</th>
                  <th>Nombre</th>
                  <th style="width: 130px">Tipo</th>
                  <th style="width: 400px">Descripción</th>
                  <th style="width: 180px; min-width: 180px">Acciones</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="categoria in categorias" :key="categoria.id">
                  <td>
                    <img
                      :src="
                        `${API}/productos/categorias/${
                          categoria.id
                        }/imagen?tipo_foto=64x64&_tk=${tk}&_tiempo=${new Date().getTime()}`
                      "
                      v-if="categoria.id_foto != null"
                      class="iconcategoria"
                    />
                  </td>
                  <td class="fw-semibold">
                    {{ categoria.id }}
                  </td>
                  <td>
                    <router-link
                      :to="{
                        name: 'edicionCategoriaProductos',
                        params: { id: categoria.id }
                      }"
                    >
                      {{ categoria.nombre }}
                    </router-link>
                  </td>
                  <td>
                    <span
                      class="badge bg-success bg-gradient font-size-12"
                      v-show="categoria.servicios"
                      >Servicio</span
                    >
                    <span
                      class="badge bg-primary bg-gradient font-size-12"
                      v-show="!categoria.servicios"
                      >Producto</span
                    >
                  </td>
                  <td>{{ categoria.descripcion }}</td>
                  <td>
                    <router-link
                      :to="{
                        name: 'edicionCategoriaProductos',
                        params: { id: categoria.id }
                      }"
                      class="btn btn-warning btn-sm"
                    >
                      <i class="mdi mdi-content-save-edit-outline"></i>
                      Editar
                    </router-link>

                    <button
                      type="button"
                      class="btn btn-danger btn-sm"
                      @click="preguntaEliminarCategoria(categoria)"
                      v-if="categoria.generica == false"
                    >
                      <i class="mdi mdi-trash-can-outline"></i>
                      Eliminar
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="text-center">
        <paginador
          :pag="pagina"
          :totpag="ultimaPagina"
          :epp="por_pagina"
          v-on:cargar-pagina="cargarPagina"
          v-on:epp-actualizado="
            por_pagina = $event,
            refrescarCategorias()
          "
        ></paginador>
      </div>
    </div>
    <br>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main"
import API from '@/API.js'
import Paginador from '@/components/Paginador.vue'
import Filtrador from '@/components/Filtrador.vue'
import CmpSecciones from '../CmpSecciones.vue'
import CategoriaProductosSrv from '@/services/CategoriaProductosSrv.js'
import Swal from 'sweetalert2'
export default {
  name: 'ListadoCategoriasProductos',
  components: { Layout, CmpSecciones, Paginador, Filtrador},
  data() {
    return {
      API: API,
      tk: localStorage.getItem('argusblack.token'),
      baseUrl: window.location.origin, 
      categorias: [],
      dato: '',
      pagina: 1,
      por_pagina: parseInt(localStorage.getItem('argusblack.listadoCategoriasProductos.por_pagina') || 10),
      ultimaPagina: 0,
      nCategoriasEnPag:0,
      nCategoriasTotales:0
    }
  },
  created: function() {
    var self = this

    // Carga de Querys
    if (this.$route.query.dato) this.dato = this.$route.query.dato
    if (this.$route.query.pagina)
      this.pagina = parseInt(this.$route.query.pagina)
    if (this.$route.query.por_pagina)
      this.por_pagina = parseInt(this.$route.query.por_pagina)

    self.refrescarCategorias()
  },
  mounted() {
    iu.spinner.mostrar('#tabla-categorias')
  },
  methods: {
    cargarPagina: function(n) {
      this.pagina = n
      this.refrescarCategorias()
    },

    eliminarCategoria: function(categoria) {
      var self = this
      
      CategoriaProductosSrv.eliminar(categoria.id).then(response=>{
        self.refrescarCategorias()
      }).catch(error=>{
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo eliminar la categoría seleccionada'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    preguntaEliminarCategoria: function(categoria) {
      var self = this

      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
      })

      swal.fire({
        title: "Eliminación de categoría",
        html: '¿Está seguro que desea eliminar la categoría <strong>'+categoria.nombre+'</strong> ?',
        icon: "warning",
        confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
        cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          CategoriaProductosSrv.eliminar(categoria.id).then(response=>{
            swal.fire("Eliminado!", "Se eliminó correctamente", "success")
            self.refrescarCategorias()
          }).catch(error=>{
            let mensaje
            try {
              mensaje = error.response.data.message
            } catch (e) {
              mensaje = 'No se pudo eliminar la categoría seleccionada'
            }
            iu.msg.error(mensaje)
            console.log(error)
          })
        } else if ( result.dismiss === Swal.DismissReason.cancel ) {
          swal.fire("Cancelar", "Se canceló la operación correctamente", "error");
        }
      })
    },

    refrescarCategorias: function() {
      var self = this

      iu.spinner.mostrar('#tabla-categorias')
      // Actualización de los querys
      this.$router.replace({
        query: {
          dato: self.dato,
          pagina: self.pagina,
          por_pagina: self.por_pagina
        }
      }).catch(error => {})

      var parametros = {
        dato: self.dato,
        pagina: self.pagina,
        por_pagina: self.por_pagina
      }

      CategoriaProductosSrv.categoriasJSON(parametros).then(response=>{
        localStorage.setItem('argusblack.listadoCategoriasProductos.por_pagina', self.por_pagina)
        let res = response.data
        self.categorias = res.categorias
        self.pagina = res.pagina
        self.ultimaPagina = res.ultima_pagina
        self.nCategoriasEnPag = res.nCategoriasEnPag
        self.nCategoriasTotales = res.total
      }).catch(error=>{
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje =  'No se pudieron cargar las categorías de productos'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(() => {
        iu.spinner.ocultar('#tabla-categorias')
      })
    }
  }
}
</script>

<style scoped>
.btnActivo,
.btnInactivo {
  cursor: pointer;
}
.iconcategoria{
  width:30px;
  height:30px;
  border-radius: 3px 3px 3px 3px;
  -moz-border-radius: 3px 3px 3px 3px;
  -webkit-border-radius: 3px 3px 3px 3px;
  border: 0px solid #000000;
  margin-right: 5px;
}
</style>